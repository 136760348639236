<template>
  <div class="coupon-section">
    <div v-if="coupon">
      <WizardForm :coupon="coupon" @coupon-changed="couponChanged"></WizardForm>
    </div>
    <div v-else class="text-center">
      <b-spinner></b-spinner>
    </div>
  </div>
</template>

<script>
import WizardForm from './WizardForm'
const merge = require('deepmerge')

export default {
  components: {
    WizardForm,
  },

  data() {
    return {
      showColorModal: false,
      couponType: null,
      selectedMode: 'design',
      selectedTab: null,
      coupon: null,
      loadingUpdate: false,
      loadingCoupon: false,
      loadingExportImage: false,
    }
  },

  computed: {
    listSections() {
      return this.sections.filter(
        (section) => section.categories.indexOf(this.coupon.type) !== -1
      )
    },

    discountTypes() {
      return this.$store.getters['coupon/discountTypes']
    },
  },

  mounted() {
    this.getCoupon()
  },

  methods: {
    getCoupon() {
      this.loadingCoupon = true

      this.$store
        .dispatch('coupon/find', this.$route.params.id)
        .then((coupon) => {
          let couponOriginal = JSON.parse(JSON.stringify(this.$store.getters['coupon/couponOriginal']))
          let tmpCoupon = coupon
          tmpCoupon.sections = merge.all([{}, 
            couponOriginal.sections,
            tmpCoupon.sections,
          ])
          this.coupon = tmpCoupon
          this.loadingCoupon = false
        })
        .catch(() => {
          this.loadingCoupon = false
        })
    },

    onTabClick(section) {
      this.selectedTab = section
    },

    update() {
      this.loadingUpdate = true

      this.$store
        .dispatch('coupon/update', this.coupon)
        .then((res) => {
          this.loadingUpdate = false
          this.$router.push({ name: 'business.coupons.index' })
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors)
          }
          this.loadingUpdate = false
        })
    },

    couponChanged(coupon) {
      this.coupon = coupon
    },
  },
}
</script>

<style lang="scss" scoped>
.card-wrapper {
  max-width: 1024px;
}

.preview-container {
  display: flex;
  justify-content: center;
}

.coupon-section {
  .coupon-types {
    .card-img-top {
      max-width: 100%;
      width: 150px;
      margin: 0 auto;
      min-height: 150px;
    }
  }
}

.preview-actions {
  margin-top: 30px;
  border-top: 1px solid rgba(204, 204, 204, 0.5);
  padding-top: 30px;
  .btn {
    width: 150px;
  }
}
</style>
